<template>
  <div id="div-magazine">
    <div>
      <Flipbook class="flipbook" :pages="imagesFlip" :pagesHiRes="imagesLarge"
      :zooms="[1,2]" :startPage="1" ref="flipbook">
        <div class="action-bar">
          <a class="btn-floating btn-small waves-effect waves-light red"
          :class="{ disabled: !canFlipLeft }"
          @click="flipBefore()" id="btn-before">
            <i class="material-icons">navigate_before</i>
          </a>
          <a class="btn-floating btn-small waves-effect waves-light red"
          :class="{ disabled: !canZoomIn }"
          @click="zoomIn()" id="btn-zoomIn">
            <i class="material-icons">add</i>
          </a>
          <a class="btn-floating btn-small waves-effect waves-light red"
          :class="{ disabled: !canZoomOut }"
          @click="zoomOut()" id="btn-zoomOut">
            <i class="material-icons">remove</i>
          </a>
          <a class="btn-floating btn-small waves-effect waves-light red"
          :class="{ disabled: !canFlipRight }"
          @click="flipNext()" id="btn-next">
            <i class="material-icons">navigate_next</i>
          </a>
        </div>
      </Flipbook>
    </div>
    <div>
      <p class="disclaimer">
        <span>
          Ofertas válidas en sucursal <b>{{selected_branch_name}}</b>.
        </span>
      </p>
    </div>
  </div>
</template>

<script>
// import M from 'materialize-css'
import Flipbook from 'flipbook-vue'
export default {
  name: 'Magazine',
  metaInfo: {
    title: 'Revista',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'descriptionMagazine',
        name: 'description',
        content: `Ofertas Grupo PETSA. Explora las últimas ofertas de Grupo PETSA
        y descubre una amplia gama de oportunidades en productos de calidad.
        ¡No te pierdas nuestras ofertas exclusivas!`
      },
    ]
  },
  components: { Flipbook },
  data(){
    return{
      numberPages:6,
      imagesFlip:[],
      imagesLarge:[],
      flipbook: null,
      canFlipLeft: false,
      canFlipRight: true,
      canZoomIn: true,
      canZoomOut: false,
      selected_branch_name: 'PETSA Express Tijuana Vía Rápida',
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.selectedBranch();
    this.link_active();
    this.arrayImages();
    this.init_flipbook();
    this.pageViewed();
  },
  methods:{
    selectedBranch(){
      if (localStorage.getItem("selectedBranch")){
        let sb = JSON.parse(localStorage.getItem("selectedBranch"));
        this.selected_branch_name = sb.name_branch;
      }
    },
    link_active(){
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
    },
    arrayImages(){
      this.imagesFlip=[
        null,
      ];
      this.imagesLarge=[
        null,
      ];
      for (let i = 1; i <= this.numberPages; i++) {
        this.imagesFlip.push(require('../assets/Revista/202410/' + i + '-.jpg'));
        this.imagesLarge.push(require('../assets/Revista/202410/' + i + '-large.jpg'));
      }
    },
    init_flipbook(){
      this.flipbook = this.$refs['flipbook'];
      const right = document.getElementsByClassName('click-to-flip right');
      right[0].classList.add("cursor-right");
    },
    flipBefore(){
      this.flipbook.flipLeft();
    },
    flipNext(){
      this.flipbook.flipRight();
    },
    zoomIn(){
      this.flipbook.zoomIn();
    },
    zoomOut(){
      this.flipbook.zoomOut();
    },
    pageViewed() {
      window.scrollTo(0, 0);
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
      this.$gtag.pageview("/magazine");
    }
  },
  watch: {
    'flipbook.canFlipLeft': {
      handler() {
        this.canFlipLeft = this.flipbook.canFlipLeft;
        const left = document.getElementsByClassName('click-to-flip left');
        if(this.canFlipLeft){
          left[0].classList.add("cursor-left");
        }else{
          left[0].classList.remove("cursor-left");
        }
      },
      deep: true
    },
    'flipbook.canFlipRight': {
      handler() {
        this.canFlipRight = this.flipbook.canFlipRight;
        const right = document.getElementsByClassName('click-to-flip right');
        if(this.canFlipRight){
          right[0].classList.add("cursor-right");
        }else{
          right[0].classList.remove("cursor-right");
        }
      },
      deep: true
    },
    'flipbook.canZoomIn': {
      handler() {
        this.canZoomIn = this.flipbook.canZoomIn;
      },
      deep: true
    },
    'flipbook.canZoomOut': {
      handler() {
        this.canZoomOut = this.flipbook.canZoomOut;
      },
      deep: true
    }
  },
}
</script>

<style  scoped>
#div-magazine{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  min-height: 720px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.flipbook {
  width: 90vw;
  height: 90vh;
  min-height: 680px;
}
.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-bar a:not(:first-child) {
  margin-left: 10px;
}
.flipbook .viewport {
  width: 90vw;
  height: 90vw;
}
.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
  min-height: 630px;
}
</style>